/**
 * @generated SignedSource<<44fc129be7a498e15ea4f3e632df3bf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RootQuery$variables = Record<PropertyKey, never>;
export type RootQuery$data = {
  readonly carModels: ReadonlyArray<{
    readonly carMake: {
      readonly id: string;
    };
    readonly carsCount: number;
    readonly id: string;
    readonly modelName: string;
  }>;
  readonly clientProfile: {
    readonly mainCarMake: {
      readonly id: string;
    };
  };
};
export type RootQuery = {
  response: RootQuery$data;
  variables: RootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "includeUnused",
        "value": false
      }
    ],
    "concreteType": "Model",
    "kind": "LinkedField",
    "name": "carModels",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "modelName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "carsCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Make",
        "kind": "LinkedField",
        "name": "carMake",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": "carModels(includeUnused:false)"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "clientId",
        "value": "SKODAPLUS"
      }
    ],
    "concreteType": "ClientProfile",
    "kind": "LinkedField",
    "name": "clientProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Make",
        "kind": "LinkedField",
        "name": "mainCarMake",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": "clientProfile(clientId:\"SKODAPLUS\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RootQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RootQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6528c6f6230879d6f88ab5bc0f102258",
    "id": null,
    "metadata": {},
    "name": "RootQuery",
    "operationKind": "query",
    "text": "query RootQuery {\n  carModels(includeUnused: false) {\n    id\n    modelName\n    carsCount\n    carMake {\n      id\n    }\n  }\n  clientProfile(clientId: \"SKODAPLUS\") {\n    mainCarMake {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b073f98fc24df8de7bf3880fd96cf807";

export default node;
