import { QueryRenderer, ReactRelayContext, graphql } from 'react-relay'
import { RootQueryQuery } from './Root.__generated__'
import { getRecommendedModelsByCars } from '../splus-lib/utils'
import { getTranslation } from '../splus-lib/utils/languageUtils'
import { gtmDataLayerPush } from '../splus-lib/utils/googleTagManager/utils'
import { withNoSSR } from '../components/withNoSSR'
import FlyingCars404 from './FlyingCars404'
import React, { useContext } from 'react'

const Root = withNoSSR(() => {
  const { environment } = useContext(ReactRelayContext)!

  const url = document.referrer.includes('skodaplus') ? new URL(document.referrer) : ''
  const urlForGA = url === '' ? '' : url.pathname + url.search + url.hash

  gtmDataLayerPush({
    event: 'trackEvent',
    eventCategory: 'Microsite - skodaplus',
    eventAction: 'Error page',
    eventLabel: '404',
    'appweb.Name': 'ms_error_page_404',
  })

  return (
    <div>
      <div className='saps-error-page-wrapper saps-error-page-wrapper--secondary'>
        <div className='text-center saps-mt-60 saps-mb-120'>
          <p className='saps-h3 saps-color-white'>{getTranslation('404.title')}</p>
          <h1 className='saps-errcode saps-color-white'>404</h1>
        </div>
        <QueryRenderer<{
          variables: any
          response: RootQueryQuery
        }>
          environment={environment}
          query={graphql`
            query RootQuery {
              carModels(includeUnused: false) {
                id
                modelName
                carsCount
                carMake {
                  id
                }
              }
              clientProfile(clientId: "SKODAPLUS") {
                mainCarMake {
                  id
                }
              }
            }
          `}
          variables={{}}
          render={({ error, props }) => {
            if (error) {
              return null
            } else if (props) {
              const mainCarMake = props.clientProfile.mainCarMake.id
              const cars = getRecommendedModelsByCars(props.carModels, mainCarMake)
              return <FlyingCars404 cars={cars} />
            }
            return null
          }}
        />
      </div>
    </div>
  )
})

export default Root
