import { getTranslation } from '../src/splus-lib/utils/languageUtils'
import FlyingCars404 from '../src/widget-404-flying-cars'
import Head from 'next/head'
import PageWrapper from '../src/wrappers/PageWrapper'
import React from 'react'
import useGTMPlaceholders from '../src/splus-lib/utils/hooks/useGTMPlaceholders'

export default function FourOhFour() {
  useGTMPlaceholders({})
  return (
    <PageWrapper footerTopOverlay={true} hasBackground={true}>
      <Head>
        <title>{getTranslation('404.page') + ' - ' + getTranslation('general.skodaPlus')}</title>
      </Head>
      <FlyingCars404 />
    </PageWrapper>
  )
}
