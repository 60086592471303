import { SPLink } from '../components/SPLink'
import { getRecommendedModelsByCars } from '../splus-lib/utils'

import React from 'react'
const degToRad = (deg: number) => deg * (Math.PI / 180)

type Props = {
  cars: ReturnType<typeof getRecommendedModelsByCars>
}

const getDefaultState = (props: Props, windowWidth: number, windowHeight: number) => ({
  startTime: null as null | number,
  progress: 0,
  playground: {
    x: 0,
    y: 0,
    width: windowWidth,
    height: windowHeight,
  },
  carImage: {
    width: 0,
    height: 0,
  },
  cars: props.cars.map(car => ({
    ...car,
    id: car.id,
    x: Math.random() * windowWidth,
    y: Math.random() * windowHeight,
    speed: Math.random() * 3,
    directionDeg: Math.random() * 360,
    rotation: Math.random() * 360,
  })),
})

type CarType = ReturnType<typeof getDefaultState>['cars'][number]

const getNextAbsolutePosition = (box: CarType) => ({
  x: box.x + Math.cos(degToRad(box.directionDeg)) * box.speed,
  y: box.y + Math.sin(degToRad(box.directionDeg)) * box.speed,
})

// > https://stackoverflow.com/a/54442598/8995887
const angleReflect = (incidenceAngle: number, surfaceAngle: number) => {
  const a = surfaceAngle * 2 - incidenceAngle
  return a >= 360 ? a - 360 : a < 0 ? a + 360 : a
}

const getNextPosition = (
  playground: ReturnType<typeof getDefaultState>['playground'],
  box: CarType,
  boxWidth: number,
  boxHeight: number
) => {
  const newPossiblePosition = getNextAbsolutePosition(box)
  let newDirectionDeg = box.directionDeg
  let newX = newPossiblePosition.x
  let newY = newPossiblePosition.y
  // left border
  if (newPossiblePosition.x <= 0) {
    newDirectionDeg = angleReflect(box.directionDeg, 90)
    newX = 0
  }
  // top border
  if (newPossiblePosition.y <= 0) {
    newDirectionDeg = angleReflect(box.directionDeg, 180)
    newY = 0
  }
  // right border
  if (newPossiblePosition.x + boxWidth >= playground.width) {
    newDirectionDeg = angleReflect(box.directionDeg, 270)
    newX = playground.width - boxWidth
  }
  // bottom border
  if (newPossiblePosition.y + boxHeight >= playground.height) {
    newDirectionDeg = angleReflect(box.directionDeg, 0)
    newY = playground.height - boxHeight
  }
  return {
    x: newX,
    y: newY,
    directionDeg: newDirectionDeg,
  }
}

class FlyingCars404 extends React.Component<Props, ReturnType<typeof getDefaultState>> {
  carImageRef: React.RefObject<HTMLImageElement>
  _frameId: number

  constructor(props: Props) {
    super(props)
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    this.state = getDefaultState(props, windowWidth, windowHeight)

    this.carImageRef = React.createRef()
  }

  componentDidMount() {
    const step = () => {
      this.setState(p => ({
        cars: p.cars.map(car => {
          const nextPosition = getNextPosition(
            p.playground,
            car,
            p.carImage.width,
            p.carImage.height
          )
          return {
            ...car,
            x: nextPosition.x,
            y: nextPosition.y,
            directionDeg: nextPosition.directionDeg,
            rotation: car.rotation + 1,
          }
        }),
      }))
      window.requestAnimationFrame(step)
    }

    this.setState({
      carImage: {
        width: this.carImageRef.current!.offsetWidth,
        height: this.carImageRef.current!.offsetHeight,
      },
    })

    this._frameId = window.requestAnimationFrame(step)
    window.addEventListener('resize', this.onChangeWindowSize)
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this._frameId)
    window.removeEventListener('resize', this.onChangeWindowSize)
  }

  onChangeWindowSize = () => {
    this.setState({
      playground: {
        x: 0,
        y: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      },
      carImage: {
        width: this.carImageRef.current!.offsetWidth,
        height: this.carImageRef.current!.offsetHeight,
      },
    })
  }

  render() {
    return (
      <div className='saps-404-car-wrapper'>
        {this.state.cars.map(car => (
          <SPLink
            href={`/list?make=${car.mainCarMake}&model=${car.id}`}
            key={car.id}
            className='saps-404-car'
            style={{
              transform: `translate(${car.x}px, ${car.y}px) rotate(${car.rotation}deg)`,
            }}
          >
            <img src={car.imageSrc} className='saps-404-car__image' ref={this.carImageRef} />
          </SPLink>
        ))}
      </div>
    )
  }
}

export default FlyingCars404
